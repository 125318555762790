import {
  endOfMonth,
  format,
  getDaysInMonth,
  isToday,
  startOfMonth
} from "date-fns";
import { addDays } from "date-fns/fp";
import React, { useEffect, useState } from "react";

interface CalendarProps {
  date: Date;
}

const weekdays = ["M", "T", "W", "T", "F", "S", "S"];

interface OptionalDate {
  date?: Date;
  highlight: boolean;
}

const Calendar: React.FC<CalendarProps> = ({ date }) => {
  const [printCalendar, setPrintCalendar] = useState([] as OptionalDate[][]);
  const start = startOfMonth(date);

  // Hvis kalenderen er tom byg den
  if (printCalendar.length === 0) {
    const end = endOfMonth(start);
    const days = new Array(start);
    for (let i = 0; i < getDaysInMonth(start) - 1; i++)
      days.push(addDays(1, days[i]));

    // Byg den kalender vi skal vise
    const print: OptionalDate[] = [];

    // Start med at fylde tomme strenge ind indtil vi rammer den 1. i måneden
    for (let i = 0; i < start.getUTCDay(); i++)
      print.push({ highlight: false });

    // Fyld derefter alle månedens dage i kalenderen
    for (const day of days)
      print.push({
        date: day,
        highlight: false
      });

    // Slut af med at fylde tomme strenge ind indtil vi rammer den sidste søndag
    for (let i = 0; i < 7 - end.getUTCDay(); i++)
      print.push({ highlight: false });

    let count = 0;
    printCalendar[count] = [];

    for (const [i, printDay] of print.entries()) {
      if (i % 7 === 0) {
        count++;
        printCalendar[count] = [];
      }
      printCalendar[count].push(printDay);
    }
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const newPrintCalendar: OptionalDate[][] = [];
      for (let i = 0; i < printCalendar.length; i++) {
        newPrintCalendar[i] = [];
        for (let j = 0; j < printCalendar[i].length; j++) {
          const optionalDate = printCalendar[i][j];
          if (optionalDate.date) {
            optionalDate.highlight = isToday(optionalDate.date);
          }
          newPrintCalendar[i][j] = optionalDate;
        }
      }
      setPrintCalendar(newPrintCalendar);
    }
  }, [date]);

  return (
    <div className="py-4 font-light">
      <div className="flex py-2 text-xl text-center border-b border-gray-900 sm:text-2xl">
        {weekdays.map((day, i) => (
          <div className="flex-1" key={day + i}>
            {day}
          </div>
        ))}
      </div>
      <div className="py-1" />
      {printCalendar.map((week, i) => (
        <div
          className="flex text-xl text-center sm:text-2xl"
          key={`calendar-row-${i}-${new Date().getTime()}`}
        >
          {week.map((day, j) => (
            <div
              key={
                day.date
                  ? `${day.date.getTime()}-${day.highlight}`
                  : `${j},${i}`
              }
              className={`flex-1 ${day.highlight ? "font-black" : ""}`}
            >
              {day.date ? format(day.date, "d") : ""}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Calendar;
