import { format } from "date-fns";
import React from "react";

interface BigDateProps {
  date: Date;
  changeMonth: (value: number) => void;
  reset: () => void;
}

const BigDate = ({ date, changeMonth, reset }: BigDateProps) => {
  const month = format(date, "MMMM");
  const year = format(date, "yyyy");

  return (
    <div className="relative flex flex-row items-center justify-between pt-4 leading-none sm:absolute sm:flex-col sm:justify-start sm:items-start disable-dbl-tap-zoom">
      <div
        className="text-2xl font-black tracking-wider cursor-pointer sm:text-5xl"
        onClick={reset}
      >
        {year}
      </div>
      <div className="text-xl font-light tracking-tighter uppercase sm:text-3xl">
        {month}
      </div>
      <div className="inline-flex pt-0 opacity-75 text-md sm:text-lg sm:opacity:100 sm:pt-2">
        <button
          className="px-4 py-2 font-black bg-gray-300 rounded-l shadow-xl focus:outline-none hover:bg-gray-100 hover:shadow-2xl"
          onClick={() => changeMonth(-1)}
        >
          ←
        </button>
        <button
          className="px-4 py-2 font-black bg-gray-300 rounded-r shadow-xl focus:outline-none hover:bg-gray-100 hover:shadow-2xl"
          onClick={() => changeMonth(1)}
        >
          →
        </button>
      </div>
    </div>
  );
};

export default BigDate;
