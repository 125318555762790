import { format } from "date-fns";
import { addMonths } from "date-fns/fp";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import React, { useState } from "react";
import { animated, useTransition } from "react-spring";
import BigDate from "../components/bigdate";
import Calendar from "../components/calendar";
import Container from "../components/container";
import Layout from "../components/layout";
import { KeofittFairyTaleData } from "../model";
import ScrollProgressRead from "react-scroll-progress-read";

export const query = graphql`
  query {
    images: allFile(
      sort: { fields: name }
      filter: { relativeDirectory: { eq: "months" } }
    ) {
      nodes {
        id
        childImageSharp {
          fluid(maxWidth: 448, traceSVG: { color: "#2e4065" }) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
    fairytales: allMarkdownRemark(sort: { fields: frontmatter___order }) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          html
        }
      }
    }
  }
`;

const IndexPage = ({ data }: { data: KeofittFairyTaleData }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const [showFairytale, setShowFairytale] = useState(false);
  const toggleFairyTale = () => setShowFairytale(showing => !showing);
  const toggleFairyTaleAndScrollToTop = () => {
    setShowFairytale(showing => !showing);
    window.scrollTo(0, 0);
  };

  const transitions = useTransition(showFairytale, null, {
    from: {
      opacity: 0,
      transform: "translate3d(0,-100%,0)"
    },
    enter: {
      opacity: 1,
      transform: "translate3d(0%,0,0)"
    },
    leave: {
      opacity: 0,
      transform: "translate3d(0,100%,0)"
    }
  });

  const image = data.images.nodes.filter(
    (_, index) => currentDate.getMonth() === index
  )[0];

  const fairytale = data.fairytales.edges.filter(
    (_, index) => currentDate.getMonth() === index
  )[0].node;

  const changeMonth = (value: number) =>
    setCurrentDate(addMonths(value, currentDate));

  const pages = [
    ({ style }: any) => (
      <>
        <animated.div style={{ ...style, backgroundColor: "#cbd5e0" }}>
          <Container>
            <div className="flex flex-col-reverse sm:flex-col">
              <div className="order-1">
                <BigDate
                  date={currentDate}
                  changeMonth={changeMonth}
                  reset={() => setCurrentDate(new Date())}
                />
              </div>

              <div
                className="flex justify-center order-5 sm:py-4"
                key={image.id}
              >
                <div
                  className="flex-auto max-w-md cursor-pointer"
                  onClick={toggleFairyTale}
                >
                  <Img
                    className="rounded-full shadow-xl hover:shadow-2xl"
                    fluid={image.childImageSharp.fluid}
                  />
                </div>
              </div>
              <div className="order-2 py-2 leading-tight text-center order-fir sm:order-10 fairytale sm:py-4">
                <button
                  onClick={toggleFairyTale}
                  className="p-4 text-xl font-normal bg-gray-300 rounded shadow-xl sm:text-2xl focus:outline-none hover:bg-gray-100 hover:shadow-2xl"
                >
                  The fairy tale of <br /> {fairytale.frontmatter.title}
                </button>
              </div>
            </div>
          </Container>
        </animated.div>

        <animated.div style={{ ...style }}>
          <Container>
            <Calendar date={currentDate} />
          </Container>
        </animated.div>
      </>
    ),
    ({ style }: any) => (
      <animated.div style={{ ...style }} className="fairytale">
        <div className="sticky top-0 bg-gray-200">
          <ScrollProgressRead backgroundColor="#edf2f6" />

          <div className="flex items-center max-w-5xl mx-auto">
            <button
              className="p-2 text-4xl font-black rounded focus:outline-none hover:bg-gray-300"
              onClick={toggleFairyTaleAndScrollToTop}
            >
              ←
            </button>
            <h1 className="text-4xl uppercase">
              {fairytale.frontmatter.title}
            </h1>
          </div>
        </div>

        <Container>
          <div
            className=""
            key={fairytale.id}
            dangerouslySetInnerHTML={{ __html: fairytale.html }}
          />
          <br />
          <button
            onClick={toggleFairyTaleAndScrollToTop}
            className="p-4 rounded shadow-xl focus:outline-none hover:bg-gray-300"
          >
            ← back to calendar
          </button>
        </Container>
      </animated.div>
    )
  ];

  return (
    <Layout title={format(currentDate, "MMM yyyy")} hideMenu={showFairytale}>
      {transitions.map(({ item, props, key }) => {
        const Page = pages[item ? 1 : 0];
        return <Page key={key} style={props} />;
      })}
    </Layout>
  );
};

export default IndexPage;
